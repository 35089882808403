import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mt-2 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!
  const _component_chart_statistic = _resolveComponent("chart-statistic")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: _ctx.name,
    selectSource: false
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_filter_component, {
        filter: _ctx.filter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
        liveReload: true,
        selectLogsFilter: false,
        selectViewFilter: false,
        slectSource: false,
        onFilterData: _ctx.getData,
        onOnLiveReload: _ctx.liveReload
      }, null, 8, ["filter", "onFilterData", "onOnLiveReload"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
      _withDirectives(_createVNode(_component_chart_statistic, { chartModel: _ctx.chartModel }, null, 8, ["chartModel"]), [
        [_vShow, _ctx.flag]
      ]),
      (!_ctx.error.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name"]))
}